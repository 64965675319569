html {
    font-size: 62.5%;
}

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    font-family: 'Noto Serif', serif;
}

h1, h2, p, blockquote, li, a, th, td {
    color: #0c0c0c;
}

header, section, aside, footer {
    padding: 2rem;
}

/* Fonts */
@font-face {
    font-family: 'Noto Serif';
    font-style: normal;
    font-weight: 400;
    src: url(/font/NotoSerif-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Noto Serif';
    font-style: normal;
    font-weight: 700;
    src: url(/font/NotoSerif-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Noto Serif';
    font-style: italic;
    font-weight: 400;
    src: url(/font/NotoSerif-Italic.ttf) format('truetype');
}

header {
    margin: 0 auto;
    width: 100%;
    max-width: 80rem;
    flex: 0 0 auto;
    padding-bottom: 0;
}

footer {
    color: #333;
    flex-shrink: 0;
    text-align: center;
}

.content {
    flex: 1;
}

.navigation {
    flex: 0 1 100px;

    ul {
        list-style-type: none;
        text-align: left;
        display: flex;
        padding: 0;
        margin: 0;

        li {
            margin-right: 1rem;
            &:after {
                content: "|";
                margin-left: 1rem;
            }
            &:last-child::after {
                content: none;
            }
        }
    }
}

.wrapper {
    margin: auto;
    width: 100%;
    max-width: 80rem;
    flex: 1 0 auto;
}

.headshot {
    width: 10rem;
    display: block;
}

@media screen and (min-width: 640px){
    header {
        display: flex;
        align-items: start;
        padding-bottom: 2rem;
    }

    .content {
        border-left: 1px solid #eee;
    }

    .flex-container {
        display: flex;
        align-items: start;
    }

    .navigation {
        ul {
            list-style-type: none;
            text-align: right;
            display: block;
            li {
                margin-right: 1rem;
                &:after {
                    content: none;
                    margin-left: 1rem;
                }
            }
        }
    }
}